import * as React from 'react'
import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {useLocalStorage} from 'react-use'
import {useRouter} from 'next/router'

import {client} from '@/client/core/utils/api-client'
import {SearchItem} from '@/client/search/components/search-box'

import {useUser} from '../context'

import {deleteNotificationToken, getNotificationToken} from './firebase-client'
import {supabase} from './supabase-client'

function useClearAuthData() {
  const {user, session} = useUser()
  return async () => {
    if (!user || !session?.access_token) return
    const notificationToken = await getNotificationToken() // Get token from cache
    client('/api/auth/logout', {
      token: session.access_token,
      data: {notificationToken},
    })
  }
}

function useClearAppData() {
  const [, , removeRecentSearches] =
    useLocalStorage<SearchItem[]>('recentSearches')

  const queryClient = useQueryClient()
  return React.useCallback(async () => {
    await deleteNotificationToken()
      .then(() => {
        console.log(`Push notification token deleted if any.`)
      })
      .catch(err => {
        console.log(`Error deleting push notification token if any.`, err)
      })
    queryClient.clear()
    removeRecentSearches()
  }, [queryClient, removeRecentSearches])
}

function useLogout() {
  const router = useRouter()
  const clearAuthData = useClearAuthData()
  const clearAppData = useClearAppData()
  return async ({
    redirect = true,
    replace,
  }: {redirect?: boolean; replace?: boolean} = {}) => {
    await clearAuthData()
    await clearAppData()
    await supabase.auth.signOut()
    const params = redirect ? `?r=${encodeURIComponent(router.asPath)}` : ''
    replace ? router.replace('/login' + params) : router.push('/login' + params)
  }
}

export {useClearAppData, useLogout}

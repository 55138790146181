import {useQuery, UseQueryOptions} from 'react-query'

import {camelcase} from '@/client/core/utils/camelcase'
import {
  getTeamAdminsQueryKey,
  getTeamMembersQueryKey,
} from '@/client/core/utils/query-keys'
import type {FullTeamMember, StoredFullTeamMember} from '@/types'

import {supabase} from './supabase-client'

// ------------------------------
// DB client
// ------------------------------

async function getTeamMembers(teamId: string) {
  const {data: members, error} = await supabase
    .from<StoredFullTeamMember>('team_members')
    .select(`*, user:users(*)`)
    .eq('team_id', teamId)
  if (error) throw error
  if (!members || !members[0]) return null
  return members.map(member =>
    camelcase<FullTeamMember>(member, [{key: 'user', type: 'object'}]),
  )
}

async function getTeamAdminMembers(teamId: string) {
  const {data: members, error} = await supabase
    .from<StoredFullTeamMember>('team_members')
    .select(`*, user:users(*)`)
    .eq('team_id', teamId)
    .eq('role', 'admin')
  if (error) throw error
  if (!members || !members[0]) return null
  return members.map(member =>
    camelcase<FullTeamMember>(member, [{key: 'user', type: 'object'}]),
  )
}

// ------------------------------
// React query
// ------------------------------

function useTeamMembers(
  teamId?: string,
  options?: UseQueryOptions<FullTeamMember[] | null, Error>,
) {
  return useQuery<FullTeamMember[] | null, Error>(
    getTeamMembersQueryKey(teamId),
    async () => {
      if (!teamId) throw new Error(`Please add teamId to the enabled field.`)
      return getTeamMembers(teamId)
    },
    {enabled: !!teamId, ...options},
  )
}

function useTeamAdmins(
  teamId?: string,
  options?: UseQueryOptions<FullTeamMember[] | null, Error>,
) {
  return useQuery<FullTeamMember[] | null, Error>(
    getTeamAdminsQueryKey(teamId),
    async () => {
      if (!teamId) throw new Error(`Please add teamId to the enabled field.`)
      return getTeamAdminMembers(teamId)
    },
    {enabled: !!teamId, ...options},
  )
}

export {getTeamMembers, useTeamAdmins, useTeamMembers}

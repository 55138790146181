import * as React from 'react'
import {HiArrowRight, HiExclamation} from 'react-icons/hi'
import {useRouter} from 'next/router'

import {useTeamAdmins} from '@/client/auth/utils/get-team-members'
import {Alert} from '@/client/core/components/alert'
import {Spacer} from '@/client/core/components/layout'
import {ButtonLink, Link} from '@/client/core/components/link'
import {slugifyHandle} from '@/client/core/utils/handle'

import 'twin.macro'

import {useCurrentUser} from '../utils/get-current-user'
import {
  getPaidSubscriptionType,
  getPaymentRequiredTeams,
  isTeamAdmin,
} from '../utils/subscription'

function AlertMember({
  teamId,
  teamName,
  teamCurrentPeriodEnd,
}: {
  teamId: string
  teamName: string | null
  teamCurrentPeriodEnd: string | null
}) {
  const {data: admins} = useTeamAdmins(teamId)
  const router = useRouter()
  return (
    <>
      <Alert type="warning">
        <p tw="text-left">
          {admins && admins.length > 0 ? (
            <span>
              현재 {teamName} 관리자는{' '}
              {admins[0].user.name || admins[0].user.email?.split('@')}
              입니다. ({admins[0].user.email}) 관리자 계정으로 로그인하면 결제를
              진행할 수 있습니다.
            </span>
          ) : (
            <p>
              현재 {teamName} 관리자가 없습니다. 콩크 팀에게 관리자를
              설정해달라고 요청해주세요.
            </p>
          )}
        </p>
      </Alert>
    </>
  )
}

function AccessErrorAction() {
  const {data: currentUser} = useCurrentUser()
  if (!currentUser) return null
  const paymentRequiredTeams = getPaymentRequiredTeams(currentUser)
  if (!paymentRequiredTeams || paymentRequiredTeams.length === 0) return null

  const manuallySubscribedTeam = paymentRequiredTeams.find(team => {
    const type = getPaidSubscriptionType(team)
    return type === 'manual'
  })

  if (manuallySubscribedTeam) {
    const isAdmin = isTeamAdmin(currentUser, manuallySubscribedTeam.id)
    return (
      <div>
        <p>
          아쉽게도 기존에 이용했던 멤버십 기간이 만료되었습니다. 새롭게 바뀐
          콩크 온라인을 다시 시작하세요!
        </p>
        <Spacer y={3} />
        {isAdmin ? (
          <ButtonLink
            size="lg"
            href={`/${slugifyHandle(
              manuallySubscribedTeam.handle,
            )}/setup/credit-card/new`}
          >
            {manuallySubscribedTeam.name} 결제하기
          </ButtonLink>
        ) : (
          <AlertMember
            teamId={manuallySubscribedTeam.id}
            teamName={manuallySubscribedTeam.name}
            teamCurrentPeriodEnd={manuallySubscribedTeam.currentPeriodEnd}
          />
        )}
        <Spacer y={4} />

        <Spacer y={4} />
        <div tw="text-left bg-washed -m-4 p-4 border-t border-muted">
          <h3>
            <span aria-hidden>🔥</span> 콩크 프로페셔널
          </h3>
          <Spacer y={2} />
          <p tw="text-gray">
            새로워진 프로페셔널은 콩크 온라인 서비스를 제한 없이 이용할 수 있는
            멤버십 플랜입니다. 소재 검색부터 소재의 모든 정보 확인, 필요한
            소재를 따로 모아두고 자료를 연결하는 것까지! 프로페셔널의 기능을
            모두 알아보세요.{' '}
            <Link
              href="/pricing"
              variant="underline"
              tw="text-primary hover:text-primary"
            >
              더 알아보기
            </Link>
          </p>
          <Spacer y={4} />
          <h3>
            <span aria-hidden>⚡️</span> 프로페셔널 비용
          </h3>
          <p tw="text-lg font-bold">3개월 99,000원 (최대 4명까지)</p>
          <Spacer y={2} />
          <p>+ 추가 인원 1인당 3개월 16,500원 부과</p>
          <p>+ 모두 부가세가 포함된 금액입니다.</p>
          <Spacer y={4} />
          <p tw="text-gray">
            프로페셔널 비용은 온라인 서비스에 대한 멤버십으로, 오프라인 샘플실
            이용은 포함되지 않습니다! 오프라인 샘플실 이용은{' '}
            <Link
              href="https://docs.concseoul.com/visit"
              variant="underline"
              tw="text-primary hover:text-primary"
            >
              여기
            </Link>
            를 참고해주세요.
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      <p>
        프로페셔널 요금제에 가입되지 않았습니다. 결제를 진행하는데 도움이
        필요하면 콩크 팀에게 알려주세요! (support@concseoul.com)
      </p>
      <div tw="mt-3 space-y-0.5">
        {paymentRequiredTeams.map(team => {
          const isAdmin = isTeamAdmin(currentUser, team.id)
          if (isAdmin) {
            return (
              <ButtonLink
                href={`/${slugifyHandle(team.handle)}/setup/credit-card/new`}
                variant="transparent"
                tw="w-full"
                suffix={HiArrowRight}
              >
                {team.name} 결제하기
              </ButtonLink>
            )
          }
          return (
            <AlertMember
              teamId={team.id}
              teamName={team.name}
              teamCurrentPeriodEnd={team.currentPeriodEnd}
            />
          )
        })}
      </div>
    </>
  )
}

function AccessError({
  error,
  resetButton,
}: {
  error: Error
  resetButton?: React.ReactNode
}) {
  return (
    <>
      <div tw="flex flex-col items-center text-center">
        <HiExclamation tw="text-4xl text-error" />
        <h2 tw="heading-lg">
          {error.message === `Abuse detected.`
            ? `후아!`
            : error.message === `Subscription required.`
            ? `결제 필요`
            : error.message === `Account disabled.`
            ? `계정 비활성화`
            : error.message === `Workspace disabled.`
            ? `작업공간 비활성화`
            : `웁스!`}
        </h2>
        <p>
          {error.message === `Abuse detected.`
            ? `소프트 리밋을 초과했습니다. 몇 분 기다렸다가 다시 시도해주세요. 일부 케이스의 경우 한 시간 소요될 수 있습니다.`
            : error.message === `Subscription required.`
            ? null // `프로페셔널 요금제에 가입되지 않았습니다. 결제를 진행하는데 도움이 필요하면 콩크 팀에게 알려주세요! (support@concseoul.com)`
            : error.message === `Account disabled.`
            ? `계정이 비활성화되었습니다. 만약 에러라고 생각된다면 콩크 팀에게 알려주세요! (support@concseoul.com)`
            : error.message === `Workspace disabled.`
            ? `작업공간이 비활성화되었습니다. 만약 에러라고 생각된다면 콩크 팀에게 알려주세요! (support@concseoul.com)`
            : error.message}
        </p>
        {error.message === `Subscription required.` && <AccessErrorAction />}
      </div>
      {resetButton && (
        <>
          <Spacer y={3} />
          {resetButton}
        </>
      )}
    </>
  )
}

export {AccessError}

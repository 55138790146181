import firebase from 'firebase/app'

import 'firebase/messaging'

if (!firebase.apps.length) {
  firebase.initializeApp({
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  })
}

const getMessaging = () => {
  if (!firebase.messaging.isSupported()) return null
  return firebase.messaging()
}

// ------------------------------
// Notification
// ------------------------------

async function getNotificationToken() {
  const messaging = getMessaging()
  return (
    messaging &&
    messaging
      .getToken({
        vapidKey: process.env.NEXT_PUBLIC_FIREBASE_WEB_PUSH_CERTIFICATE,
      })
      .catch(err => console.error(`Error getting push notification token`, err))
  )
}

async function deleteNotificationToken() {
  const messaging = getMessaging()
  return messaging && messaging.deleteToken()
}

export {deleteNotificationToken, getMessaging, getNotificationToken}

import * as React from 'react'
import type {FallbackProps} from 'react-error-boundary'
import {HiExclamation} from 'react-icons/hi'
import {Global} from '@emotion/react'
import {useRouter} from 'next/router'
import tw from 'twin.macro'

import {AccessError} from '@/client/auth/components/access-error'
import {useLogout} from '@/client/auth/utils/logout'

import {Button} from '../button'
import {Card, Spacer} from '../layout'
import {ButtonLink, Link} from '../link'
import {Logo} from '../logo'

function Layout({children}: {children: React.ReactNode}) {
  return (
    <>
      <Global styles={{html: tw`font-size[16px]`}} />
      <div tw="h-20 flex items-center justify-center">
        <Logo />
      </div>
      <div tw="flex items-center justify-center min-height[calc(100vh - 12rem)]">
        {children}
      </div>
    </>
  )
}

function formatError(error: Error) {
  let message
  switch (error.message) {
    case 'JWT expired':
      message = '페이지를 새로고침해주세요.'
      break
    case 'Never':
      message = '시스템 에러입니다.'
      break
    case '404':
      message = '페이지를 찾을 수 없습니다.'
      break
    default:
      message = error.message
  }
  return message
}

const ACCESS_ERROR_MESSAGES = [
  'Account disabled.',
  'Subscription required.',
  'Workspace disabled.',
]

/**
 * Catch 404 and other fatal app errors
 */
function ErrorFallback({error, resetErrorBoundary}: FallbackProps) {
  const router = useRouter()

  const logout = useLogout()

  React.useEffect(() => {
    console.log(`ErrorFallback`, router.pathname, error)
  }, [error, router.pathname])

  if (ACCESS_ERROR_MESSAGES.includes(error.message)) {
    return (
      <main tw="container-md mt-5" role="alert">
        <Card variant="focus">
          <div tw="flex flex-col items-center text-center">
            <AccessError error={error} />
          </div>
        </Card>
        <Spacer y={2} />
        <div tw="flex flex-col items-center text-center">
          <Button
            variant="transparent"
            tw="underline"
            onClick={() => {
              logout({
                redirect: false,
                replace: false,
              })
            }}
          >
            로그아웃
          </Button>
          <Spacer y={4} />
        </div>
      </main>
    )
  }

  return (
    <Layout>
      <main tw="container-md" role="alert">
        <Card variant="focus">
          <div tw="flex flex-col items-center text-center">
            <HiExclamation tw="text-4xl text-error" />
            <h2 tw="heading-lg">
              {error.message === '404' ? '404' : `문제가 발생했습니다`}
            </h2>
            <p>{formatError(error)}</p>
          </div>

          <Spacer y={3} />

          <ButtonLink size="lg" tw="w-full" href="/">
            홈으로 돌아가기
          </ButtonLink>

          <Spacer y={2} />

          {error.message !== '404' && (
            <p tw="text-center caption">
              만약 같은 에러가 계속 발생한다면{' '}
              <Link href="mailto:support@concseoul.com" variant="underline">
                support@concseoul.com
              </Link>
              으로 문의주세요.
            </p>
          )}
        </Card>
      </main>
    </Layout>
  )
}

export {ErrorFallback}

import {
  HiCheckCircle,
  HiExclamation,
  HiInformationCircle,
  HiXCircle,
} from 'react-icons/hi'
import ReachAlert from '@reach/alert'
import tw from 'twin.macro'

type AlertProps = {
  children: React.ReactNode
  title?: string
  type?: 'error' | 'warning' | 'info' | 'success'
}

const alertIcons = {
  error: <HiXCircle tw="h-6 w-6 text-error" />,
  warning: <HiExclamation tw="h-6 w-6 text-warning" />,
  info: <HiInformationCircle tw="h-6 w-6 text-info" />,
  success: <HiCheckCircle tw="h-6 w-6 text-success" />,
}

const alertStyles = {
  error: tw`text-error-text bg-error-background`,
  warning: tw`text-warning-text bg-warning-background`,
  info: tw`text-info-text bg-info-background`,
  success: tw`text-success-text bg-success-background`,
}

function Alert({title, type = 'error', children, ...props}: AlertProps) {
  return (
    <ReachAlert {...props}>
      <div tw="p-3 rounded-lg" css={alertStyles[type]}>
        <div tw="flex">
          <div tw="flex-shrink-0">{alertIcons[type]}</div>
          <div tw="ml-2">
            {title && <h3 tw="font-medium mb-1">{title}</h3>}
            <div>{children}</div>
          </div>
        </div>
      </div>
    </ReachAlert>
  )
}

export type {AlertProps}
export {Alert}
